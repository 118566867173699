import React from 'react';
import {
	Row,
	Col,
	Card,
	Icon,
	Carousel,
	Empty,
	Divider,
	Modal,
	Button
} from 'antd';
import Mayre from 'mayre';
import Filter from './Filter';
import BrandInfo from './BrandInfo';
import PRODUCTS from '../../products';
import {BRAND_INFO} from '../../constants/globals';
import GeneralCatalog from './GeneralCatalog';

class ProductList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			products: PRODUCTS,
			selectedBrand: "",
			carousel: null,
			showCarousel: false,
			catalog: ""
		}
		window.scrollTo(0,0);
	}
	
	filterProducts = (products, reset=false) => this.setState(() => ({products: reset ? PRODUCTS : products}));

	showCarousel = (carousel, catalog={name: "", brand: ""}) => this.setState((prevState) => ({showCarousel: !prevState.showCarousel, carousel: carousel, catalog: catalog}));

	selectBrand = (selectedBrand) => this.setState(() => ({selectedBrand}));

	render() {
		let products = this.state.products.map((product, index) => {
			const carousel = (
				<Carousel effect="fade" autoplay>
					{product.img.map((item) => (
						<div className="productItem" key={item}>
							<div className="img" style={{background: `url("/images/products/${product.name} img${item}.png") center center`}}/>
						</div>
					))}
				</Carousel>
			)
			return (<Col className="product" key={index} xs={24} sm={12} md={8} lg={6} xl={6}>
						<div className={`brand ${product.brand}`}>
							<img className="img" src={`/images/brands/${product.brand}.png`} alt={product.brand}/>
						</div>
						<Card
							bodyStyle={{padding: '10px 20px'}}
							cover={(
								<div onClick={() => this.showCarousel(carousel, product)} style={{cursor: "pointer"}}>
									{carousel}
								</div>
							)}
							actions={[<a key="download" href={`/catalogs/${product.brand}/${product.name}.pdf`} download={product.name}><span><Icon type="download"/> Catalogo </span></a>]}
						>
							<Card.Meta 
								className="title" 
								title={product.name}
								description={product.description}
							/>
						</Card>
					</Col>);
		});

		return (
			<Row type="flex" justify="center" align="middle">
				<Col span={24}>
					<GeneralCatalog />
				</Col>
				<Col span={22} className="filters">
					<Filter filter={this.filterProducts} selectBrand={this.selectBrand}/>
				</Col>
				<Col  span={22} id="brandWrapper" className="productTitle">
					<Divider/>
				</Col>
				<Mayre
					of={
						<React.Fragment>

							<Col xs={22} sm={22} md={20} lg={12} xl={10} className="filters">
								<BrandInfo brand={this.state.selectedBrand.length ? BRAND_INFO[this.state.selectedBrand] : undefined} />
							</Col>
							<Col  span={22} className="productTitle">
								<Divider/>
							</Col>
						</React.Fragment>
					}
					when={this.state.selectedBrand.length > 0}
				/>
				<Col span={24}>
					<Mayre
						of={
							<React.Fragment>
								{products}
							</React.Fragment>
						}
						or={
							<Empty
								description={
									<span>
										No hay resultados para el filtro.
									</span>
								}
							/>
						}
						when={this.state.products.length > 0}
					/>
				</Col>
				<Modal
					title={this.state.catalog.name}
					className="productModal"
					visible={this.state.showCarousel}
					onCancel={() => this.showCarousel()}
					footer={[<Button icon="download" key="download" href={`/catalogs/${this.state.catalog.brand}/${this.state.catalog.name}.pdf`} download={this.state.catalog.name}>Catalogo</Button>]}
				>
					<div className="catalogModal">
						{this.state.carousel}
					</div>
				</Modal>
			</Row>
		);
	}
}

export default ProductList;
