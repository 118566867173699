import React from 'react';
import {
	Row,
	Col,
	Divider,
	Carousel
} from 'antd';

class Brands extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			images: [
				'event1',
				'event2',
				'event3',
				'event4',
				'event5',
			]
		}
	}

	render() {
		let events = this.state.images.map((event, index) => {
			return (
				<div key={index}>
					<img className="img" src={`/images/events/${event}.jpg`} alt={event}/>
				</div>
			)
		});
		
		return (
			<Row type="flex" justify="center" align="middle" className="difference">
				<Col  xs={24} sm={24} md={18} lg={18} xl={18}>
					<Col span={24} className="title">
						<Col xs={24} sm={24} md={21} lg={16} xl={14}>
							<span>Nuestra Diferencia</span>
						</Col>
						<Col xs={0} sm={0} md={3} lg={8} xl={10}>
							<Divider />
						</Col>
					</Col>
				</Col>
				<Col span={24}>
					<Carousel className="events" autoplay>
						{events}
					</Carousel>
				</Col>
			</Row>
		);
	}
}

export default Brands;
