import React from 'react';
import {
	Layout,
	Icon,
	Row,
	Col,
	Divider
} from 'antd';
import moment from 'moment';

const {
	Footer,
} = Layout;

class MyFooter extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Footer>
				<Row type="flex" justify="center" align="middle" className="footer">
					<Col xs={22} sm={18} md={18} lg={18} xl={18}>
						<Col xs={18} sm={13} md={16} lg={18} xl={18} className="copyright">
							Comercel &#169; {moment().format('YYYY')}
						</Col>
						<Col xs={6} sm={11} md={8} lg={6} xl={6} className="social">
							<Col  xs={0} sm={14} md={14} lg={14} xl={14}>
								<Divider />
							</Col>
							<Col  xs={24} sm={10} md={10} lg={10} xl={10}>
								<Col span={12}>
									<a href="https://www.instagram.com/comercelhn/" rel="noopener noreferrer" target="_blank" className="icon">
										<Icon type="instagram"/>
									</a>
								</Col>
								<Col span={12}>
									<a href="https://www.facebook.com/Comercelhn/" rel="noopener noreferrer" target="_blank" className="icon">
										<Icon type="facebook"/>
									</a>
								</Col>
							</Col>
						</Col>
					</Col>
				</Row>
				
			</Footer>
		);
	}
}

export default MyFooter;
