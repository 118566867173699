export default [
	{
		category: "herramientas",
		brand: "ls",
		name: "Breakers Susol",
		img: [1],
		description: "Los breakers Susol LS ofrecen Super Desempeño, super capacidad de corte en muchas aplicaciones de la industria"
	},
	{
		category: "herramientas",
		brand: "ls",
		name: "Variadores ls",
		img: [1, 2, 3, 4],
		description: "LS ofrece diversidad en cuanto a variadores especializados, desde aplicaciones residenciales monofásicas con el IC5 hasta aplicaciones trifasicas de bombeo con el Modelo H100 y aplicaciones de torque Constante con los modelos IS7 y S100"
	},
	{
		category: "herramientas",
		brand: "ls",
		name: "Guardamotores ls",
		img: [1],
		description: "Protege tus motores con los diseños compactos de guardamotores LS, Capacidades hasta 100amperios."
	},
	{
		category: "herramientas",
		brand: "ls",
		name: "Breakers Metasol",
		img: [1],
		description: "Los breakers de Caja moldeada de la serie Metasol de LS, son compactos y con una capacidad de corte del 100% ICU"
	},
	{
		category: "herramientas",
		brand: "ls",
		name: "Contactor-Rele",
		img: [1],
		description: "Contactores y rele de Sobrecarga con diseños compactos, facilidad de conexión, alto desempeño y sellados, ofrecen una seguridad al usuario final"
	},
	{
		category: "herramientas",
		brand: "ls",
		name: "Mini Breakers BKN y BKN-B",
		img: [1],
		description: "Los mini breakers LS, ideales para protección contra sobrecarga y corto circuito hasta 400V con curvas de Disparo A,B y C. Presentacion en 1, 2 y 3 polos, Capacidades desde 1A a 63A."
	}
];