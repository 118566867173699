import React, { Component } from 'react';
import Slider from './Slider';
import Brands from './Brands';
import Categories from './Categories';
import Difference from './Difference';

class Home extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<div className="home">
				<Slider />
				<Brands />
				<Categories />
				<Difference />
			</div>
		);
	}
}

export default Home;