import React from 'react';
import { Document, Page } from 'react-pdf';
import { Row, Col, Pagination, Spin } from 'antd';
import Banner from '../layouts/Banner';

class index extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			numPages: null,
			pageNumber: 1,
			pdfWidth: this.calculateWidth()
		}
	}

	componentDidMount() {

		window.addEventListener("resize", this.onResize);
	}
	
	componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	calculateWidth = () => window.innerWidth * 0.7;

	onResize = () => this.setState(({ pdfWidth: this.calculateWidth()}));

	onDocumentLoadSuccess = ({ numPages }) => this.setState({numPages});

	onPageChange = (pageNumber) => this.setState((prevState) => prevState.numPages >= pageNumber ? ({pageNumber}) : null)

	render() {
		const { pageNumber, numPages, pdfWidth } = this.state;
		const file = "../../catalogs/general/Catalogo Resumido.pdf";
		const Pages = (<Pagination showQuickJumper current={pageNumber} onChange={this.onPageChange} pageSize={1} total={numPages}/>);
		return (
			<Row type="flex" justify="space-around" align="middle" className="view">
				<Col span={24}>
					<Banner section="general" wrapper="generalWrapper"/>
				</Col>
				<Col span={24} className="pages">
					{Pages}
				</Col>
				<Col span={24} className="catalog-page">
					<Document
						file={file}
						onLoadSuccess={this.onDocumentLoadSuccess}
						loading={<Spin className="loadingPDF" tip="Cargando..." size="large"/>}
					>
						<Page 
							pageNumber={pageNumber}
							width={pdfWidth}
							loading={<Spin className="loadingPDF" tip="Cargando..." size="large"/>}
						/>
					</Document>
				</Col>
				<Col span={24} className="pages">
					{Pages}
				</Col>
			</Row>	
		);
	}
}

export default index;
