import React from 'react';
import Banner from '../layouts/Banner';
import {
	Row,
	Col
} from 'antd';
import ProductList from './ProductList';

class index extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Row className="catalog" type="flex" justify="space-around" align="middle">
				<Col span={24}>
					<Banner section="catalog"/>
				</Col>
				<Col span={24} className="view">
					<ProductList />
				</Col>
			</Row>
		);
	}
}

export default index;
