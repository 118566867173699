import React from 'react';
import {
	Row,
	Col,
	Typography
} from 'antd';
import Banner from '../layouts/Banner';
import Map from './Map';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';

const {Title} = Typography;

class index extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Row className="contact" type="flex" justify="space-around" align="middle">
				<Col span={24}>
					<Banner section="contact"/>
				</Col>
				<Col span={24} className="view">
					<Row type="flex" justify="space-around" align="middle" className="section">
						<Col span={24}>
							<Title className="slogan">Soluciones Técnologicas Alternativas</Title>
						</Col>
					</Row>
					<Row type="flex" justify="space-around" align="middle" className="section">
						<Col xs={24} sm={24} md={24} lg={10} xl={10} className="subSection">
							<ContactForm />
						</Col>
						<Col xs={24} sm={24} md={24} lg={10} xl={10} className="subSection">
							<Map />
						</Col>
					</Row>
					<Row type="flex" justify="space-around" align="top" className="section">
						<ContactInfo />
					</Row>
				</Col>
			</Row>
		);
	}
}

export default index;
