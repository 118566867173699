import React from 'react';
import PropTypes from 'prop-types';
import {
	Form,
	Input,
	Col,
	Button,
	message
} from 'antd';
import axios from 'axios';

const FormItem = Form.Item;
const { TextArea } = Input;

class ContactForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();

		this.props.form.validateFields((err, values) => {
			if (!err) {
				let mail = {
					email: values.email,
					subject: values.subject,
					surname: values.surname,
					first: values.first,
					message: values.message,
					secure: "UA-140418015-1"
				}
				
				this.setState({
					loading: true
				});
				axios.post(`/Correos/Send`, mail)
				.then( () => {
					message.success("Recibimos tu información, nos contactaremos lo mas pronto posible.");

				})
				.catch( () => {
					message.error("Algo no esta bien, intenta mas tarde.");
				})
				.then(() => {
					this.setState({
						loading: false
					});
				});
			}
		});
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<Form className="form">
				<Col span={12} className="formField">
					<FormItem
						label="Nombre"
					>
						{
							getFieldDecorator('first', {
								rules: [{
									required: true, 
									message: '¡No olvides poner tu nombre!',
								}]
							})(
								<Input />
							)
						}
					</FormItem>
				</Col>
				<Col span={12} className="formField">
					<FormItem
						label="Apellido"
					>
						{
							getFieldDecorator('surname', {
								rules: [{
									required: true, 
									message: '¡No olvides poner tu apellido!',
								}]
							})(
								<Input />
							)
						}
					</FormItem>
				</Col>
				<Col span={24} className="formField">
					<FormItem
						label="Correo"
					>
						{
							getFieldDecorator('email', {
								rules: [{
									type: 'email', 
									message: 'The input is not valid E-mail!',
								}, {
									required: true, 
									message: '¡No olvides poner tu e-mail!',
								}]
							})(
								<Input />
							)
						}
					</FormItem>
				</Col>
				<Col span={24} className="formField">
					<FormItem
						label="Asunto"
					>
						{
							getFieldDecorator('subject', {
								rules: [{
									required: true, 
									message: '¡No olvides poner un asunto!',
								}]
							})(
								<Input />
							)
						}
					</FormItem>
				</Col>
				<Col span={24} className="formField">
					<FormItem
						label="Mensaje"
					>
						{
							getFieldDecorator('message', {
								rules: [{
									required: true, 
									message: '¡Necesitamos saber de ti para ayudarte!',
								}]
							})(
								<TextArea rows={4} />
							)
						}
					</FormItem>
				</Col>
				<Col span={24} className="formField">
					<Button type="primary" size="large" onClick={this.handleSubmit} loading={this.state.loading}>
						<span className="icon-paper-plane-o" /> Enviar
					</Button>
				</Col>
			</Form>
		);
	}
}

ContactForm.propTypes = {
	form: PropTypes.object
};

export default Form.create({ name: 'contact_form' })(ContactForm);
