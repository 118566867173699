import React from 'react';
import PropTypes from 'prop-types';
import {
	Col,
	List,
	Typography,
	Row
} from 'antd';
import Mayre from 'mayre';

const {Paragraph, Title} = Typography;

class BrandInfo extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {brand} = this.props;

		if(!brand) return null;

		document.getElementById('brandWrapper').scrollIntoView();

		return (
			<Row type="flex" justify="center" className="brand">
				<Col span={24}>
					<img className="img" src={`/images/brands/${brand.name}.png`} alt={brand.name}/>
				</Col>
				<Col span={24}>
					<Paragraph>
						{brand.description}
					</Paragraph>
				</Col>
				<Col span={8}>
					<Mayre
						of={
							<React.Fragment>
								<List
									grid = {
										{
											xs: 1,
											sm: 1,
											md: 2,
											lg: 2,
											xl: 2,
											xxl: 2,
										}
									}
									header={<Title level={4}>Productos</Title>}
									size="small"
									bordered={false}
									dataSource={brand.products}
									renderItem={item => <List.Item>{item}</List.Item>}
								/>

							</React.Fragment>
						}
						when={brand.products.length > 0}
					/>
				</Col>
			</Row>
		);
	}
}

BrandInfo.propTypes = {
	brand: PropTypes.object
};

export default BrandInfo;
