export default [
	{
		category: "residencial",
		brand: "segurimax",
		name: "26568",
		img: [1, 2, 3],
		description: "La luminaria 1200 lm IP65 Titan fue fabricada con tecnología LED para iluminación de emergencia. Es utilizada en recintos internos y externos y ofrece un gran ámbito de alcance. Puede utilizarse en almacenes, galpones industriales, hoteles, hospitales, estadios, entre otros ambientes con fuerte concentración de polvo y donde haya exposición a chorros de agua."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "31250",
		img: [1],
		description: "La luminaria LED 200 Lumenes 2.4W con faros laterales fue fabricada con tecnología LED para iluminación de emergencia. Es utilizada en recintos internos y es ideal para oficinas, hoteles, restaurantes, centros comerciales, residencias, y muchos otros."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "25919",
		img: [1, 2],
		description: "La luminaria 400 lúmenes 2 faros fue fabricada con tecnología LED para iluminación de emergencia. Es utilizada en recintos internos y es ideal para oficinas, hoteles, restaurantes, centros comerciales, residencias, y muchos otros."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "24777",
		img: [1, 2, 3, 4],
		description: "La luminaria 3000 lúmenes 2 faros fue fabricada con tecnología LED para iluminación de emergencia. Es utilizada en recintos internos y es ideal para oficinas, hoteles, restaurantes, bodegas, almacenes, centros comerciales, y muchos otros."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "24707",
		img: [1, 2, 3],
		description: "La luminaria 1200 lúmenes 2 faros fue fabricada con tecnología LED para iluminación de emergencia. Es utilizada en recintos internos y es ideal para oficinas, hoteles, restaurantes, bodegas, almacenes, centros comerciales, y muchos otros."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "24532",
		img: [1, 2, 3, 4],
		description: "Equipo diseñado y desarrollado en nuestra empresa y fabricado en una moderna fábrica en China para la iluminación de emergencia (aclaramiento) en: recibidores de edificios, pasillos, garajes, residencias, oficinas, escuelas, hospitales y muchos otros."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "24699",
		img: [1, 2, 3, 4],
		description: "La luminaria 50 lúmenes ofrece tecnología en LED SMD de alto brillo, durabilidad y gran iluminación. Con un tamaño compacto, es facilmente transportada y conectada en diferentes tipos de toma corriente por tener un enchufe rectráctil."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "25685",
		img: [1, 2, 3, 4],
		description: "Equipo diseñado y desarrollado en nuestra empresa y fabricado en una moderna fábrica en China para la iluminación de emergencia"
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "28049",
		img: [1],
		description: "La luminaria 90 LEDs fue fabricada con la tecnología Led, desarrollada para iluminación de emergencia (aclaramiento). Utilizada en escaleras de acceso, ruta de fuga, edificios, escuelas, corredores, entre otros."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "26570",
		img: [1],
		description: "La luminaria Línea Económica Grande fue fabricada con tecnología LED para iluminación de emergencia. Utilizada en galpones, bodegas, edifi cios, indústrias, estádios, entre otros."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "26569",
		img: [1],
		description: "La luminaria Línea Económica Pequeña fue fabricada con tecnología LED para iluminación de emergencia . Utilizada en galpones, bodegas, edifi cios, indústrias, estádios, entre otros."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "23596",
		img: [1, 2, 3, 4],
		description: "La luminaria 30 LEDs fue fabricada con tecnología LED para iluminación de emergencia. Es utilizada en recintos internos y es ideal para oficinas, hoteles, restaurantes, centros comerciales, residencias, y muchos otros."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "24530",
		img: [1, 2, 3],
		description: "La luminaria 200 lúmenes 2 faros fue fabricada con tecnología LED para iluminación de emergencia. Es utilizada en recintos internos y es ideal para oficinas, hoteles, restaurantes, centros comerciales, residencias, y muchos otros."
	},
	// {
	// 	category: "residencial",
	// 	brand: "segurimax",
	// 	name: "25684",
	// 	img: [1, 2, 3],
	// 	description: "La luminaria 200 lúmenes 2 faros fue fabricada con tecnología LED para iluminación de emergencia. Es utilizada en recintos internos y es ideal para oficinas, hoteles, restaurantes, centros comerciales, residencias, y muchos otros."
	// },
	{
		category: "residencial",
		brand: "segurimax",
		name: "30847",
		img: [1],
		description: "La luminaria LED 150 Lumenes IP23 fue fabricada con tecnología LED para iluminación de emergencia. Es utilizada en recintos internos y es ideal para oficinas, hoteles, restaurantes, centros comerciales, residencias, y muchos otros."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "25327",
		img: [1],
		description: "Las señaléticas cumplen con la función de indicar las rutas de escape/salida, así pues son adecuadas para centros comerciales, bodegas, galpones, indústrias, y muchas otras aplicaciones. Son bivolt automáticos así pues se adaptan a la necesidad de su región."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "25328",
		img: [1],
		description: "Las señaléticas cumplen con la función de indicar las rutas de escape/salida, así pues son adecuadas para centros comerciales, bodegas, galpones, indústrias, y muchas otras aplicaciones. Son bivolt automáticos así pues se adaptan a la necesidad de su región."
	},
	{
		category: "residencial",
		brand: "segurimax",
		name: "26301",
		img: [1],
		description: "Fabricada con tecnología LED para iluminación de emergencia, está diseñada para permanecer siempre encendida, garantizando ahorro de energía y autonomía en caso de apagón. "
	}
];


