import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Affix,
	Button
} from 'antd';
import {push} from 'react-router-redux';

class GeneralCatalog extends React.Component {

	render() {
		const {goToPage} = this.props;

		return (
			<Affix className="catalogAffix" offsetTop={100}>
				<Button size="large" type="primary" onClick={() => goToPage("/catalogo-general")} icon="file-pdf" >Catalogo General</Button>
			</Affix>
		);
	}
}

GeneralCatalog.propTypes = {
	goToPage: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		goToPage: (page) => dispatch(push(page))
	};
};

export default connect(null, mapDispatchToProps)(GeneralCatalog);
