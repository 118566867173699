import React from 'react';
import {
	Carousel
} from 'antd';

class Slider extends React.Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		return (
			<div className="slider">
				<Carousel dots={false} autoplay>
					<div>
						<div className="img"/>
						<div className="overlay-black"/>
						<div className="overlay-purple"/>
						<div className="overlay-blue"/>
					</div>
					<div>
						<div className="img banner2"/>
						<div className="overlay-black"/>
						<div className="overlay-purple"/>
						<div className="overlay-blue"/>
					</div>
					<div>
						<div className="img banner3"/>
						<div className="overlay-black"/>
						<div className="overlay-purple"/>
						<div className="overlay-blue"/>
					</div>
				</Carousel>
				<div className="logo-wrapper">
					<img src="images/logo.svg" alt="" className="logo" />
				</div>
			</div>
		);
	}
}

export default Slider;
