import React from 'react';
import {
	Col,
	Typography,
	Divider,
	Icon
} from 'antd';

const { Text, Title } = Typography;

class ContactInfo extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<React.Fragment>
				<Col xs={24} sm={24} md={24} lg={10} xl={10}>
					<Col span={24} className="title">
						<Col xs={18} sm={12} md={10} lg={16} xl={12}>
							<span>San Pedro Sula</span>
						</Col>
						<Col xs={6} sm={12} md={14} lg={8} xl={12}>
							<Divider />
						</Col>
					</Col>
					<Col span={24}>
						<Title level={4} className="h4Title">Dirección</Title>
						<Text className="text">Bo. Medina 15 Calle, 2-3 Ave.</Text>
					</Col>
					<Col span={24}>
						<Title level={4} className="h4Title">Apartado Postal</Title>
						<Text className="text">No. 2998</Text>
					</Col>
					<Col span={24}>
						<Title level={4} className="h4Title">Teléfonos</Title>
						<Text className="text">
							<Icon type="phone" /> - 2557-2217 
						</Text>
						<br/>
						<Text className="text">
							<Icon type="phone" /> - 800-2557-1604
						</Text>
						<br/>
						<Text className="text">
							<Icon type="phone" /> - 2557-2326
						</Text>
						<br/>
						<Text className="text">
							<strong>Bodega: </strong> 2554-0550
						</Text>
						<br/>
						<Text className="text">
							<strong>Fax: </strong> 2557-9142
						</Text>
					</Col>
				</Col>
				<Col xs={24} sm={24} md={24} lg={10} xl={10}>
					<Col span={24} className="title">
						<Col xs={15} sm={11} md={8} lg={12} xl={12}>
							<span>Tegucigalpa</span>
						</Col>
						<Col xs={9} sm={13} md={16} lg={12} xl={12}>
							<Divider />
						</Col>
					</Col>
					<Col span={24}>
						<Title level={4} className="h4Title">Dirección</Title>
						<Text className="text">Barrio Concepción 4ta. Avenida 10-11 Calle local #1022 Comayagüela.</Text>
					</Col>
					<Col span={24}>
						<Title level={4} className="h4Title">Teléfonos</Title>
						<Text className="text">
							<Icon type="phone" /> - 2220-6691 
						</Text>
						<br/>
						<Text className="text">
							<Icon type="phone" /> - 2220-6745
						</Text>
						<br/>
						<Text className="text">
							<strong>Fax: </strong> 2222-0911
						</Text>
					</Col>
				</Col>
			</React.Fragment>
		);
	}
}

export default ContactInfo;
