import React, { Component } from 'react';
import {
	Row,
	Col,
	Divider,
	Typography
} from 'antd';

const { Text } = Typography;

class Values extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		let valores = ["Honestidad", "Actitud de Servicio", "Transparencia", "Integridad", "Responsabilidad", "Respeto", "Perseverancia"];
		
		return (
			<Col span={24} className="valores">
				<Row type="flex" justify="space-around" align="middle">
					<Col span={24} className="title">
						<Divider>Valores</Divider>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12} className="list">
						{valores.map((item, index) => (
							<Col key={index} xs={12} sm={8} md={8} lg={6} xl={6} >
								<Text>{item}</Text>
							</Col>
						))}
					</Col>
				</Row>
			</Col>
		);
	}
}

export default Values;



