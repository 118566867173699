import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import routes from './routes';
import {Router} from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/lib/locale-provider/es_ES'; //ES

class Root extends Component {
	render() {
		const { store, history, persistor } = this.props;
		return (
			<Provider store={store}>
				<ConfigProvider locale={es_ES}>
					<PersistGate loading={null} persistor={persistor}>
						<Router history={history} routes={routes}/>
					</PersistGate>
				</ConfigProvider>
			</Provider>
		);
	}
}

Root.propTypes = {
	store: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	persistor: PropTypes.object.isRequired,
};

export default Root;