import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Divider,
	Button
} from 'antd';
import {push} from 'react-router-redux';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';

class Brands extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			animateIn: "",
			brands: [{
					name: "ls",
					description: "LS industrial Systems fundada en 1974, lidera el mercado de automatización en procesos industriales y equipos en baja y media tensión."
				}, {
					name: "generalCable",
					description: "Empresa líder en la manufactura de cables y soluciones alámbricas a nivel mundial"
				}, {
					name: "schneider",
					description: "Lidera la transformación de la gestión de energía en casas, centro de datos infraestructura e industria, ofreciendo soluciones confiables, eficientes y sustentables para todos los procesos."
				}, {
					name: "autonics",
					description: "Proveedor de soluciones totales en sensores y controladores que ofrece más de 5000 artículos a los diversos campos de la automatización industrial."
				}
			]
		}
	}

	componentDidMount(){
		this.setState({
			animateIn: 'fadeInUp'
		})
	}

	render() {
		const {goToPage} = this.props;
		const {animateIn, brands} = this.state;

		let brandStars = brands.map((brand, index) => {
			if(index%2 == 0){
				return (
					<Row key={index} type="flex" justify="space-between" align="middle" className="brandWrapper">
						<Col xs={24} sm={24} md={10} lg={10} xl={10} className="brandLogo">
							<ScrollAnimation animateOnce={true} animateIn={animateIn}>
								<img src={`/images/brands/${brand.name}.png`} alt={brand.name}/>
							</ScrollAnimation>
						</Col>
						<Col xs={24} sm={24} md={14} lg={14} xl={14}>
							<ScrollAnimation animateOnce={true} animateIn={animateIn}>
								<p>{brand.description}</p>
								<Divider />
							</ScrollAnimation>
						</Col>
					</Row>
				)
			}

			return (
				<Row key={index} type="flex" justify="space-between" align="middle" className="brandWrapper">
					<Col xs={{span: 24, order: 1}} sm={24} md={{span: 14, order: 0}} lg={14} xl={14}>
						<ScrollAnimation animateOnce={true} animateIn={animateIn}>
							<p>{brand.description}</p>
							<Divider />
						</ScrollAnimation>
					</Col>
					<Col xs={{span: 24, order: 0}} sm={24} md={{span: 10, order: 1}} lg={10} xl={10} className="brandLogo">
						<ScrollAnimation animateOnce={true} animateIn={animateIn}>
							<img src={`/images/brands/${brand.name}.png`} alt={brand.name}/>
						</ScrollAnimation>
					</Col>
				</Row>
			)
		});

		return (
			<Row type="flex" justify="center" align="middle" className="brands">
				<Col xs={22} sm={18} md={18} lg={18} xl={18}>
					<Col span={24} className="title">
						<Col xs={11} sm={11} md={8} lg={7} xl={6}>
							<span>Marcas</span>
						</Col>
						<Col xs={13} sm={13} md={16} lg={17} xl={18}>
							<Divider />
						</Col>
					</Col>
					<Col span={24}>
						{brandStars}
					</Col>
					<Col span={24} className="moreBrands">

						<ScrollAnimation animateOnce={true} animateIn={animateIn}>
							<Button type="primary" shape="circle" icon="plus" size="large" onClick={() => goToPage('/productos')}/>
						</ScrollAnimation>
					</Col>
				</Col>
			</Row>
		);
	}
}

Brands.propTypes = {
	goToPage: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		goToPage: (page) => dispatch(push(page))
	};
};

export default connect(null, mapDispatchToProps)(Brands);
