export default [
	{
		category: "desarmadores",
		brand: "wera",
		name: "Desarmadores Aislado",
		img: [1, 2],
		description: "Juego de destornilladores Kraftform Comfort VDE de alta calidad. Con un mango Kraftform multicomponente para un trabajo rápido y ergonómico. La punta Black Point de Wera así como un procedimiento de endurecimiento sofisticado garantizan una larga vida útil de la punta, una mayor protección anticorrosiva y una gran exactitud de posicionamiento."
	},
	{
		category: "desarmadores",
		brand: "wera",
		name: "Desarmadores Wera Micro 12",
		img: [1, 2],
		description: "Destornillador Micro de Wera para aplicaciones de mecánica de precisión, en un juego. Por medio del apoyo de la mano en el sombrerete giratorio y gracias a la zona de giro rápido situada por debajo del sombrerete giratorio, se consigue un movimiento de retorcimiento ultrarrápido. Se gana tiempo al no tener que cambiar de agarre una y otra vez."
	},
	{
		category: "desarmadores",
		brand: "wera",
		name: "Desarmadores Wera Bornero 6 pz",
		img: [1, 2],
		description: "Juego de destornilladores Wera Micro de alta calidad, para uniones atornilladas de mecánica de precisión. Por medio del apoyo de la mano en el sombrerete giratorio, y gracias a la zona de giro rápido situada por debajo del sombrerete giratorio, se consigue un movimiento de retorcimiento ultrarrápido. Se gana tiempo al no tener que cambiar de agarre una y otra vez. La zona de potencia con las zonas blandas que lleva integradas permite la transmisión de pares altos de desapriete y apriete."
	},
	{
		category: "desarmadores",
		brand: "wera",
		name: "Desarmadores Wera Set Intercambiable 16pz",
		img: [1, 2],
		description: "14 varillas VDE intercambiables con destornillador porta-puntas 817 VDE Kraftform y buscapolos en bolso robusto para llevar en el cinturón. Herramientas probadas una por una según la norma IEC 60900."
	},
	{
		category: "desarmadores",
		brand: "wera",
		name: "Desarmador Wera set intercambiable 17pz",
		img: [1, 2],
		description: "15 varillas intercambiables VDE con 2 porta-puntas 817 VDE Kraftform en bolso robusto para llevar en el cinturón. Herramientas probadas una por una según la norma IEC 60900. "
	},
	{
		category: "desarmadores",
		brand: "wera",
		name: "Desarmador Compacto Wera portátil",
		img: [1, 2],
		description: "Las herramientas compactas de Wera permiten la utilización simultánea de las dos clases de atornillar 'manual' y 'mecánica'. El sistema de cambio, de geometría muy compacta, de mango y varilla con diferentes puntas de la varilla en un espacio mínimo, le proporcionan al usuario una enorme movilidad y flexibilidad."
	},
	{
		category: "desarmadores",
		brand: "wera",
		name: "Desarmadores Wera Serie 100",
		img: [1, 2],
		description: "Juego de destornilladores de 7 piezas con destornilladores aislados, El mango es tipo Kraftform Plus para trabajo agradable y ergonómico sin que se produzcan ampollas o callosidades. Las zonas duras de agarre permiten una gran velocidad de trabamientras que las zonas blandas de agarre garantizan la transmisión de altos pares de giro."
	},
	{
		category: "ratchet",
		brand: "wera",
		name: "Ratchet Zyklop Speed Wera 28pz metrico",
		img: [1, 2],
		description: "Una configuración del juego increíblemente compacta que ofrece muchísima herramienta en muy poco espacio. Se obtiene una sensación completamente nueva con relación a esta clase de juegos de carraca gracias, a las novedosas cajas de material textil sumamente robustas y duraderas que además se pueden posicionar de forma vertical para conseguir un mayor orden en la estantería del taller."
	},
	{
		category: "ratchet",
		brand: "wera",
		name: "Ratchet Zyklop Speed Wera 28pz Imperial",
		img: [1, 2],
		description: "Una configuración del juego increíblemente compacta que ofrece muchísima herramienta en muy poco espacio. Se obtiene una sensación completamente nueva con relación a esta clase de juegos de carraca gracias, a las novedosas cajas de material textil sumamente robustas y duraderas que además se pueden posicionar de forma vertical para conseguir un mayor orden en la estantería del taller. El material exterior textil de la caja ofrece además una protección adicional de las superficies."
	},
	{
		category: "llaves",
		brand: "wera",
		name: "Llaves Torx Multicolor",
		img: [1, 2],
		description: "Llave acodada con camisa envolvente de plástico (SPKL) elaborada de un material redondeado agradable al tacto y agarre. Esta camisa facilita el trabajo bajo temperaturas bajas, lo hace más agradable y cuida los dedos. Todas las llaves acodadas llevan su codificación a color y sus marcas de tamaño lo que facilita la selección rápida del útil correcto."
	},
	{
		category: "accesorios",
		brand: "wera",
		name: "Bolsa de Herramientas wera",
		img: [1, 2],
		description: "La caja de herramientas compacta Wera 2go puede ser equipada con herramientas y piezas de recambio, y se puede acoplar a Wera 2go 1, 2 y 5."
	},
	{
		category: "accesorios",
		brand: "wera",
		name: "Magnetizador de destornilladores Wera",
		img: [1, 2],
		description: "Aparato de magnetización de Wera para magnetizar y desmagnetizar la herramienta con el fin de que el tornillo se sostenga de forma segura en la varilla."
	},
	{
		category: "accesorios",
		brand: "wera",
		name: "Sujeta Tornillos Wera",
		img: [1, 2],
		description: "Garra de atornillar de Wera: herramienta conectable con función de retención para tornillos. Sostiene todos los perfiles de tornillos de forma segura en la herramienta. Durante la acción de apriete o desapriete, la garra de atornillar se puede desplazar hacia arriba en la varilla. De esta forma, la garra no molesta incluso en situaciones de montaje de mayor profundidad."
	}
];
