import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Button
} from 'antd';
import {push} from 'react-router-redux';

class Banner extends Component {
	constructor(props) {
		super(props);
		this.state = {
			about: "Nosotros",
			contact: "Contáctanos",
			catalog: "Productos",
			general: "Catalogo General",
		};
	}
	render() {
		const {section, wrapper, goToPage} = this.props;
		return (
			<Row className={`layoutBanner ${wrapper}`}>
				<Col span={24}>
					<div className={`img ${section}`}/>
					<div className="overlay-black"/>
					<div className="overlay-blue"/>
					<div className="bannerTitle">{this.state[section]}</div>
					<div className="back">
					<Button type="link" className="link" icon="arrow-left" onClick={() => goToPage('/productos')} /></div>
				</Col>
			</Row>
		);
	}
}

Banner.defaultProps = {
  wrapper: ''
}

Banner.propTypes = {
	section: PropTypes.string,
	wrapper: PropTypes.string,
	goToPage: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		goToPage: (page) => dispatch(push(page))
	};
};

export default connect(null, mapDispatchToProps)(Banner);