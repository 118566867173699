import React, {
	Component
} from 'react';
import PropTypes from 'prop-types';
import {
	Layout
} from 'antd';
import MyHeader from './MyHeader';
import MyFooter from './MyFooter';

const {
	Content
} = Layout;

class Main extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<Layout>
				<MyHeader />
				<Content>
					<div>
						{...this.props.children}
					</div>
				</Content>
				<MyFooter />
			</Layout>
		);
	}
}

Main.propTypes = {
	children: PropTypes.object.isRequired
};

export default Main;






