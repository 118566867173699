import React from 'react';
import PropTypes from 'prop-types';
import {
	Row,
	Col,
	Divider
} from 'antd';
import {BRANDS} from '../../constants/globals';
import PRODUCTS from '../../products';

class Filter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedBrand: ""
		}
	}

	selectBrand = (brand) => {
		this.setState((prevState) => {
			this.props.filter(
				PRODUCTS
					.filter((product) => prevState.selectedBrand != brand ? product.brand == brand : true)
			);
			let selectedBrand = prevState.selectedBrand != brand ? brand : "";
			this.props.selectBrand(selectedBrand);
			return {selectedBrand}
		});
	};

	render() {
		const {selectedBrand} = this.state;

		return (
			<Row type="flex" justify="start" align="middle" className="brands">
				<Col span={24}>
					<Divider orientation="left">Marcas</Divider>
				</Col>
				{BRANDS.map( (brand, index) => (
					<Col 
						xs={8} sm={6} md={4} lg={2}
						key={index}
						className={`brand ${selectedBrand == brand ? "selected" : ""}`}
						onClick={() => this.selectBrand(brand)}
					>
						<img className={`img ${brand}`} src={`/images/brands/${brand}.png`} alt={brand}/>
					</Col>
				))}
			</Row>
		);
	}
}

Filter.propTypes = {
	filter: PropTypes.func,
	selectBrand: PropTypes.func
};

export default Filter;
