import React, {Component} from 'react';
import PropTypes from 'prop-types';

// main layout imports
import {Layout} from 'antd';
const {Content} = Layout;
import MyHeader from './MyHeader';
import MyFooter from './MyFooter';

class Empty extends Component {
	render() {
		return (
			<Layout className="emptyLayout">
				<MyHeader />
				<Content className="content">
					{this.props.children}
				</Content>
				<MyFooter />
			</Layout>
		);
	}
}

Empty.propTypes = {
	children: PropTypes.object.isRequired,
};

export default Empty;