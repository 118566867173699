import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Result, Button } from 'antd';
import {push} from 'react-router-redux';

class NotFound extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		const {goToPage} = this.props;

		return (
				<Result
					status="404"
					title="404"
					subTitle="Lo sentimos esta pagina no existe."
					extra={<Button type="primary" onClick={() => goToPage('/')}>Inicio</Button>}
				/>
			);
	}
}

NotFound.propTypes = {
	goToPage: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		goToPage: (page) => dispatch(push(page))
	};
};

export default connect(null, mapDispatchToProps)(NotFound);