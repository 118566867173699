import React, { Component } from 'react';
import Banner from '../layouts/Banner';
import {
	Row,
	Col
} from 'antd';
import History from './History';
import Porpuse from './Porpuse';
import Values from './Values';


class About extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<Row className="about" type="flex" justify="space-around" align="middle">
				<Col span={24}>
					<Banner section="about"/>
				</Col>
				<Col span={24} className="view">
					<History />
					<Porpuse />
					<Values />
				</Col>
			</Row>
		);
	}
}

export default About;



