export default [
	// {
	// 	category: "residencial",
	// 	brand: "artMark",
	// 	name: "Uniones EMT Tornillo",
	// 	img: [1, 2]
	// },
	// {
	// 	category: "residencial",
	// 	brand: "artMark",
	// 	name: "Conectores Romex",
	// 	img: [1, 2]
	// },
	// {
	// 	category: "residencial",
	// 	brand: "artMark",
	// 	name: "Tuberia, uniones y curvas Rigidas",
	// 	img: [1, 2]
	// },
	// {
	// 	category: "residencial",
	// 	brand: "artMark",
	// 	name: "Tapas Intemperie",
	// 	img: [1, 2]
	// },
	// {
	// 	category: "residencial",
	// 	brand: "artMark",
	// 	name: "Tuberia y Curvas APC",
	// 	img: [1, 2]
	// },
	// {
	// 	category: "residencial",
	// 	brand: "artMark",
	// 	name: "Conector LT Curvo",
	// 	img: [1, 2]
	// },
	// {
	// 	category: "residencial",
	// 	brand: "artMark",
	// 	name: "Conectores EMT Tornillo",
	// 	img: [1, 2]
	// },
	{
		category: "residencial",
		brand: "artMark",
		name: "Conector BX Curvo",
		img: [1],
		description: "Equipos y accesorios para tubería, fittings, conectores, condulets, abrazaderas y equipos para instalaciones eléctricas en general."
	},
	// {
	// 	category: "residencial",
	// 	brand: "artMark",
	// 	name: "Conector LT Recto",
	// 	img: [1, 2]
	// },
	{
		category: "residencial",
		brand: "artMark",
		name: "Cajas Intemperie 2 Gang",
		img: [1],
		description: "Equipos y accesorios para tubería, fittings, conectores, condulets, abrazaderas y equipos para instalaciones eléctricas en general."
	},
	{
		category: "residencial",
		brand: "artMark",
		name: "Conector BX Recto",
		img: [1, 2],
		description: "Equipos y accesorios para tubería, fittings, conectores, condulets, abrazaderas y equipos para instalaciones eléctricas en general."
	},
	{
		category: "residencial",
		brand: "artMark",
		name: "Cajas Intemperie 1 Gang",
		img: [1],
		description: "Equipos y accesorios para tubería, fittings, conectores, condulets, abrazaderas y equipos para instalaciones eléctricas en general."
	},
	{
		category: "residencial",
		brand: "artMark",
		name: "Condulets APC",
		img: [1, 2, 3],
		description: "Equipos y accesorios para tubería, fittings, conectores, condulets, abrazaderas y equipos para instalaciones eléctricas en general."
	}
];