import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Row,
	Col,
	Button,
	Divider
} from 'antd';
import {push} from 'react-router-redux';

class MyFooter extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const {goToPage} = this.props;

		return (
			<Row type="flex" justify="space-around">
				<Col xs={22} sm={24} className="menuSide">
					<Col span={24}>
						<Button type="link" className="link" onClick={() => goToPage('/')}>
							Inicio
						</Button>
						<Divider type="vertical" />
						<Button type="link" className="link" onClick={() => goToPage('/productos')}>
							Productos
						</Button>
						<Divider type="vertical" />
						<Button type="link" className="link" onClick={() => goToPage('/contact')}>
							Contacto
						</Button>
						<Divider type="vertical" />
						<Button type="link" className="link" onClick={() => goToPage('/about')}>
							Nosotros
						</Button>
					</Col>
				</Col>

			</Row>
		);
	}
}

MyFooter.propTypes = {
	goToPage: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
	return {
		goToPage: (page) => dispatch(push(page))
	};
};

export default connect(null, mapDispatchToProps)(MyFooter);
