import React, { Component } from 'react';
import {
	Row,
	Col,
	Divider
} from 'antd';


class History extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Col span={24} className="history">
				<Row type="flex">
					<Col span={24} className="title">
						<Col xs={11} sm={8} md={6} lg={4} xl={4}>
							<span>Historia</span>
						</Col>
						<Col xs={13} sm={16} md={18} lg={20} xl={20}>
							<Divider />
						</Col>
					</Col>

					<Col xs={{span: 24, order: 1}} sm={24} md={{span: 9, order: 0}} lg={9} xl={9} className="logo">
						<img src="images/logo.svg" alt=""/>
					</Col>
					<Col xs={{span: 24, order: 0}} sm={24} md={{span: 15, order: 1}} lg={15} xl={15} className="content">
						Comercial Eléctrica es una empresa que ofrece equipo eléctrico y soluciones de automatización para la industria desde el año 2002, estableciéndose con sede principal en San Pedro Sula. Para el año 2007 debido a la demanda generada en la zona centro de Honduras, se inaugura una segunda sucursal en la ciudad de Tegucigalpa, cubriendo los requerimientos de la zona centro y sur del país, gracias al desarrollo obtenido desde el comienzo, se ha adquirido la distribución de muchas marcas prestigiosas y reconocidas a nivel mundial.
					</Col>
				</Row>
			</Col>
		);
	}
}

export default History;



