export default [
	{
		category: "residencial",
		brand: "schneider",
		name: "Familia Variadores Altivar",
		img: [1, 2, 3],
		description: "Schneider ofrece diversidad de soluciones para ejercer control sobre motores, la familia altivar comprende de variadores compactos como el ATV12 hasta equipos especializados para aplicaciones industriales de alto desempeño en el cual los modelos ATV600 y ATV900 son destacados."
	},
	{
		category: "residencial",
		brand: "schneider",
		name: "Rele Termicos",
		img: [1],
		description: "Los rele térmicos LRD de Schneider, ofrecen un diseño compacto y fácil de operar y Capacidades desde 0.63A hasta 150A."
	},
	{
		category: "residencial",
		brand: "schneider",
		name: "Contactores",
		img: [1],
		description: "Los Contactores modelo LC1D en capacidades hasta 150 A, usualmente se aplican en motores de induccion jaula de ardilla, aplicaciones AC-3."
	},
	{
		category: "residencial",
		brand: "schneider",
		name: "Guardamotores",
		img: [1],
		description: "Los guardamotores magnetotérmicos GV2 ofrecen gran maniobra y protección para motores de inducción."
	}
];