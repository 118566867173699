export const BRANDS = ["autonics", "generalCable", "ls", "schneider", "fluke", "aucom", "artMark", "photonX", "knipex", "famatel", "clamper", "amprobe", "segurimax", "wera"];

export const CATEGORIES = [
	{
		name: "AUTOMATIZACIÓN",
		icon: "automatizacion"
	}, {
		name: "MEDICIÓN E INDICACIÓN",
		icon: "medicion"
	}, {
		name: "CONTROL Y MANIOBRA",
		icon: "maniobra"
	}, {
		name: "HERRAMIENTAS",
		icon: "herramientas"
	}, {
		name: "ENSAMBLES",
		icon: "tools-2"
	}, {
		name: "ILUMINACIÓN Y LINEA RESIDENCIAL",
		icon: "residencial"
	}, 
];

export const BRAND_INFO = {
	"autonics": {
		name: "autonics",
		description: "Autonics es un proveedor líder de soluciones de automatización de Corea del Sur. Ofreciendo una amplia gama de productos de automatización que se comercializan en todo el mundo. Los productos principales incluyen varios sensores, controladores, dispositivos de movimiento, equipos de medición, sistemas de marcado láser, equipos de conexión. Autonics ofrece productos confiables y adoptados por ingenieros en diversas aplicaciones industriales y la tecnología adoptada se aplica ampliamente en dispositivos de automatización cotidianos.",
		products: ["Sensores", "Controladores", "Dispositivos de movimiento", "Equipos de medición", "Sistemas de marcado láser", "Equipos de conexión"]
	}, 
	"generalCable": {
		name: "generalCable",
		description: "En 2018, General Cable se fusionó con Prysmian y se convirtió en parte del Grupo Prysmian el cual es líder mundial en la industria de sistemas de cable de energía y telecomunicaciones ofreciendo la más amplia gama de servicios y conocimientos en el negocio, con casi 140 años de experiencia. Cada año, el Grupo fabrica miles de millas de cables y sistemas subterráneos y submarinos para la transmisión y distribución de energía, así como cables de media y baja tensión para los sectores de construcción e infraestructura.",
		products: ["Energía", "Telecomunicaciones"]
	}, 
	"ls": {
		name: "ls",
		description: "Estamos liderando el camino hacia un nuevo futuro a través de innovaciones que superan las expectativas de nuestros clientes",
		products: ["Breakers", "Variadores", "Guardamotores", "Contarores", "relés"]
	}, 
	"schneider": {
		name: "schneider",
		description: "Lidera la transformación de la gestión de energía en casas, centro de datos infraestructura e industria, ofreciendo soluciones confiables, eficientes y sustentables para todos los procesos.",
		products: ["Variadores", "Guardamotores", "Contarores", "relés"]
	}, 
	"fluke": {
		name: "fluke",
		description: "Fluke Corporation es el líder mundial en la fabricación, distribución y mantenimiento de equipos de medida electrónicos y software. Las oficinas de servicio y ventas se reparten por Europa, América del Norte y del Sur, Asia y Australia . Fluke Corporation cuenta con distribuidores y representantes de fabricación autorizados en más de 100 países, y dispone de una plantilla de aproximadamente 2.400 personas .",
		products: ["Camaras termograficas", "Medidores", "Multimetros", "Termometros", "Amperimetros"]
	}, 
	"aucom": {
		name: "aucom",
		description: "Especialistas en control de motores de velocidad fija, proveen soluciones para arranque de motores en baja y media tensión, teniendo una operación a nivel mundial para proporcionar los conocimientos y el apoyo para ayudar a que las cosas salgan bien desde el principio.",
		products: ["Arrancadores"]
	}, 
	"artMark": {
		name: "artMark",
		description: "Compañia Lider en componentes industriales como tubería, accesorios, pernos, curvas y cajas metálicas.",
		products: ["Tubería", "Accesorios", "Pernos", "Curvas", "Cajas metálicas"]
	}, 
	"photonX": {
		name: "photonX",
		description: "Con una experiencia de 64 años en el mercado, PhotonX LED y Photonx Solar, forman parte de Grupo Jademar, los cuales son líderes en el mercado de iluminación ofreciendo soluciones a nivel industrial y residencial.",
		products: ["Photon led", "Photon solar"]
	}, 
	"knipex": {
		name: "knipex",
		description: "Fundada en 1882, Wuppertal, Alemania, fabricando alicates y tenazas para herraje, Knipex es una compañía familiar precedida por 3 generaciones, siendo hoy líder mundial en fabricación, venta e innovación de alicates, enfocándose en un grupo selectivo de herramientas logrando así ventajas palpables en calidad y rendimiento.",
		products: ["Alicates corte diagonal", "Tenazas", "corta cables", "pelacables", 'Llaves para tubería']
	}, 
	"famatel": {
		name: "famatel",
		description: "Con presencia en 35 paises y 5 continentes, Famatel, fabricante de clavijas y tomas industriales, armarios industriales, cajas de derivación, ofrece una calidad insuperable en toda su gama de productos.",
		products: ["Clavijas", "Tomas industriales", "Armarios industriales", "Cajas de derivación"]
	}, 
	"clamper": {
		name: "clamper",
		description: "Fundada en 1991, CLAMPER ofrece soluciones de mercado para la protección de equipos electrónicos contra daños causados por rayos y sobretensiones. Pionera en Brasil en el segmento, la compañía comenzó su trayectoria desarrollando proyectos 100% personalizados de dispositivos de protección contra sobretensiones (DPS) para las principales compañías de exploración de energía, telecomunicaciones, ferrocarriles, minería y exploración de petróleo y gas.",
		products: []
	}, 
	"amprobe": {
		name: "amprobe",
		description: "Construyendo herramientas de prueba y medición resistentes y confiables desde 1948 en las que han confiado electricistas profesionales y técnicos de climatización durante más de 70 años. Los productos van desde una extensa línea de pinzas amperimétricas y multímetros digitales hasta herramientas específicas de la industria para electricistas residenciales / comerciales, técnicos de HVAC, servicios públicos y profesionales de mantenimiento industrial.",
		products: []
	},
	"wera": {
		name: "wera",
		description: "Wera Ofrece destornilladores de alta calidad, desde los más sencillos hasta en acero inoxidable, aislados para ambientes especiales, ofrece llaves de alta calidad acodadas tipo TORX y hexagonales. Todas las llaves acodadas llevan su codificación a color y sus marcas de tamaño lo que facilita la selección rápida del útil correcto. Wera como innovación en el mercado ofrece sus ratchet o carracas con 3 tipos de tecnología, Zyklop Speed, Push Through y Metal Switch.",
		products: ["Desarmadores", "Llaves", "Juegos de Ratch", "Accesorios"]
	},
	"segurimax": {
		name: "segurimax",
		description: "Segurimax es una empresa orientada a la eficiencia y alto rendimiento y por lo tanto no mide esfuerzos para traer al mercado los mejores productos y soluciones del segmento de iluminación de emergencia. Idealizada y conducida por gestores con más de 30 años de actuación en el segmento de prevención contra incendios, la Segurimax vino a innovar y cubrir esta brecha que carecía el mercado de Luces de emergencia",
		products: ["Producto industrial", "Producto residencial", "Señales"]
	}

}