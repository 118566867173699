import React from 'react';
import {
	Row,
	Col,
	Divider
} from 'antd';
import {CATEGORIES} from '../../constants/globals';

class Categories extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		let span = {
			xs: 12,
			sm: 12,
			md: 8,
			lg: 8,
			xl: 8
		};
		const categories = CATEGORIES.map((category, index) => (
			<Col {...span} className="category" key={index}>
				<Col span={24}>
					<span className={`category-icon icon-${category.icon}`} />
				</Col>
				<Col span={24} className="category-title">
					{category.name}
				</Col>
			</Col>
		))
		return (
			<Row type="flex" justify="center" align="middle" className="categories">
				<Col xs={22} sm={18} md={18} lg={18} xl={18}>
					<Col span={24} className="title">
						<Divider>Industrias</Divider>
					</Col>
					<Col span={24}>
						<Row type="flex" justify="space-between" className="categoryWrapper">
							{categories}
						</Row>
					</Col>
				</Col>
			</Row>
		);
	}
}

export default Categories;
