export default [
	{
		category: "residencial",
		brand: "photonX",
		name: "Photon Led",
		img: [1, 2, 3, 4],
		description: "Productos de Iluminación LED Solar ofreciendo variedad de soluciones para alumbrado publico, residencial, ambientes interiores y ambientes exteriores."
	},
	{
		category: "residencial",
		brand: "photonX",
		name: "Photon Solar",
		img: [1, 2, 3, 4],
		description: "Ofreciendo una diversidad de luminarias con la tecnología LED, PhotonX apuesta por el ahorro energético ofreciendo Calidad en todos sus equipos."
	}
];