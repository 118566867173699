import React from 'react';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
} from "react-google-maps";

class Map extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {

		const MapWithAMarker = withScriptjs(withGoogleMap(() =>
			<GoogleMap
				defaultZoom={8}
				defaultCenter = {
					{
						lng: -87.617338,
						lat: 14.796461
					}
				}
				options = {
					{
						fullscreenControl: false,
						streetViewControl: false,
					}
				}
			>
				<Marker
					position = {{
						lng: -88.024074,
						lat: 15.492420
					}}
				/>
				<Marker
					position = {{
						lng: -87.2083679,
						lat: 14.0951094
					}}
				/>
			</GoogleMap>
		));

		return (
			<MapWithAMarker
				googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA7S5wcqaFRbTZolQxqbG9erM_q55TSYpw&v=3.exp"
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div id="mapContainer" style={{ height: `443px` }}/>}
				mapElement={<div style={{ height: `100%` }} />}
			/>
		);
	}
}

export default Map;
