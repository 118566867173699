export default [
	{
		category: "residencial",
		brand: "aucom",
		name: "Arrancador EMX3",
		img: [1],
		description: "El arrancador Suave EMX3 provee un sistema completo de arranque de motor para aplicaciones de alto desempeño con un impresionante rango de funciones amigables al usuario final."
	},
	{
		category: "residencial",
		brand: "aucom",
		name: "Arrancador Suave EMX4",
		img: [1],
		description: "El arrancador Suave EMX4, es el ultimo en su tecnología, mas compacto, mejora la productividad en cada operación debido a su precisión y eficacia operativa proporcionando datos significativos de sus arranque y estado, abriéndose a una amplia gama de soluciones industriales de medio y alto desempeño."
	}
];