export default [
	{
		category: "residencial",
		brand: "generalCable",
		name: "Cable TSJ",
		img: [1, 2, 3],
		description: "Cable Multi conductor Flexible utilizado en extensiones para equipo de bajo consumo a 600V."
	},
	{
		category: "residencial",
		brand: "generalCable",
		name: "Cable Super Flex" ,
		img: [1, 2, 3, 4],
		description: "Mono y Multi Conductor Extra Flexibles, aislación XLPE y Cubierta de PVC"
	},
	{
		category: "residencial",
		brand: "generalCable",
		name: "Cable THHN" ,
		img: [1, 2, 3, 4, 5, 6],
		description: "Cable de Uso general en instalaciones domiciliarias y comerciales."
	},
	{
		category: "residencial",
		brand: "generalCable",
		name: "Cable SPT" ,
		img: [1],
		description: "Cable para uso domestico utilizado para extensiones en equipos de bajo consumo."
	}
];