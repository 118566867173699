import ls from './ls';
import autonics from './autonics';
// import horus from './horus';
import generalCable from './generalCable';
import schneider from './schneider';
import fluke from './fluke';
import aucom from './aucom';
import artMark from './artMark';
import photonX from './photonX';
import knipex from './knipex';
import famatel from './famatel';
import clamper from './clamper';
import amprobe from './amprobe';
import wera from './wera';
import segurimax from './segurimax';

export default [
	...ls,
	...autonics,
	// ...horus,
	...generalCable,
	...schneider,
	...fluke,
	...aucom,
	...artMark,
	...photonX,
	...knipex,
	...famatel,
	...clamper,
	...amprobe,
	...segurimax,
	...wera
]