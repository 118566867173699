import React, { Component } from 'react';
import {
	Col
} from 'antd';

class Porpuse extends Component {
	constructor(props) {
		super(props);
	}
	render() {
		return (
			<Col span={24} className="mvContainer">
				<Col xs={24} sm={24} md={12} lg={12} xl={12} className="left">
					<Col span={24} className="title">
						Visión
					</Col>
					<Col span={24} className="content">
						Convertirnos en la empresa líder comercializadora de materiales eléctricos y automatización a nivel nacional conocida por brindar las mejores soluciones en productos de automatización y control industrial. Consolidar la confianza de nuestros clientes, mediante las diferentes gamas de productos y marcas representadas. Cultivar relaciones personales y comerciales exitosas con honestidad, transparencia y una mejora continúa en todos nuestros procesos. 
					</Col>
				</Col>
				<Col xs={24} sm={24} md={12} lg={12} xl={12} className="right">
					<Col span={24} className="title">
						Misión
					</Col>
					<Col span={24} className="content">
						Exceder las expectativas de nuestros clientes, ofreciendo el mejor servicio, producto de calidad y brindando soporte técnico en los diferentes productos, ofreciendo un valor agregado a nuestros clientes convirtiéndonos en un aliado estratégico en todos sus proyectos.
					</Col>
				</Col>
			</Col>
		);
	}
}

export default Porpuse;
